
import React, { useEffect, useState } from "react";
import moment from "moment";
import Grid from "@mui/material/Grid";
import axios from "axios";
import Links from "../../../src/Data/Links.js";
import FfunOneDashboardAccounting from "../../component/Reports/FfunOneDashboardAccounting.js";
import SummaryReportsTableAllDivisions from "../../component/Reports/SummaryReportsTableAllDivisions.js";
import CarLoading from "../../images/487.gif";
import BarChart from "../../component/Charts/BarChart.js"
import getFiscals from '../../utils/getFiscals';

export default function FfunOneDashboardAccountingModal({division,Modalmonth,Modalyear,week,handleSaveAccounting}) {
  const divisions = ['Auto', 'Rec', 'Used', 'FFUN Financial'];
  const currentYear = moment().format("YYYY");
  const currentMonth = moment().format("MMM");
  const [month, setMonth] = useState(Modalmonth);
  const [year, setYear] = useState(Modalyear);
  const [category, setCategory] = useState("New Vehicles");
  const [loading, setLoading] = useState(true);
  const [showNumbers, setShowNumbers] = useState(true);











  const handleYearChange = (event) => {
    setYear(event.target.value);
  };

  const handleMonthChange = (event) => {
    setMonth(event.target.value);
  };

  const handleCateogryChange = (event) => {
    setCategory(event.target.value);
  };


  return (
    <div className="mx-auto" style={{ marginTop: "3rem", width: "100%" }}>
            <Grid container spacing={2}>
           

                <Grid item container xs={12}>
                    <Grid className="mx-auto overflow-auto" >
           
          
              <>
               

{
                                division=="Auto" &&    <FfunOneDashboardAccounting
                                division='Auto'
                                Modalmonth={Modalmonth}
                                Modalyear={Modalyear}
                                week={week}
                                handleSaveAccounting={handleSaveAccounting}
                       
                              />
                                
                            }

                            {
                                division=="Rec" && 
                                <FfunOneDashboardAccounting
                                division='Rec'
                                Modalmonth={Modalmonth}
                                Modalyear={Modalyear}
                                week={week}
                                handleSaveAccounting={handleSaveAccounting}
                              />
                           }

                            {
                                division=="Used" && 
                                <FfunOneDashboardAccounting
                                division='Used'
                                Modalmonth={Modalmonth}
                                Modalyear={Modalyear}
                                week={week}
                                handleSaveAccounting={handleSaveAccounting}
                              />
                           }
              </>
            
          </Grid>
        </Grid>
 
      </Grid>
    </div>
  );
}
