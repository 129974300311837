import React from 'react'
import { calculateTotalGP } from '../../utils';
import { DEFAULT_TABLE_TITLES } from './../../constants'

const isValueArray = (data, type) => {
  let value;
  if (Array.isArray(type.DATA_VALUE_TWO)) {
    value = data[`${type.DATA_VALUE_TWO[0]}`] + data[`${type.DATA_VALUE_TWO[1]}`];
  } else {
    value = data[`${type.DATA_VALUE_TWO}`];
  }
  return parseFloat(value?.toFixed()).toLocaleString();
};

const renderVehiclesRow = (dailyDataThisMonth, stores, type) => {
  if (dailyDataThisMonth && stores) {
    return <>
      <tr>
        <td style={{ paddingTop: '10px', textAlign: 'center', fontSize: '1rem', fontWeight: 'bolder', color: '', whiteSpace: 'nowrap' }}>
          {type.LABEL}
        </td>
        <td>
          <table style={{ width: '100%' }}>
            <tr>
              <td style={{ fontWeight: 'normal', fontSize: '1rem', whiteSpace: 'nowrap' }}>
                {type.TITLE_TWO}
              </td>
            </tr>
            <tr>
              <td style={{ fontWeight: 'normal', fontSize: '1rem', whiteSpace: 'nowrap' }}>
                {type.TITLE_ONE}
              </td>
            </tr>

          </table>
        </td>
        {stores && stores.map((store) => {
          const dailyDataForCurrentStore = dailyDataThisMonth.find(key => store === key.store_name)
          if (dailyDataForCurrentStore) {
            return <>
              <td>
                {type.LABEL === 'Total' ?
                  <>
                    <table style={{ width: '100%' }}><tr style={{ textAlign: 'center' }}>${calculateTotalGP(dailyDataForCurrentStore)?.toLocaleString()}</tr></table>
                  </> :
                  <>
                    <table style={{ width: '100%' }}>
                      {type.LABEL !== 'Services' && <>
                        <tr>
                          <td style={{ fontWeight: 'normal', fontSize: '1rem', textAlign: 'center' }}>
                            ${isValueArray(dailyDataForCurrentStore, type)}
                          </td>
                        </tr>
                      </>
                      }
                      <tr>
                        <td style={{ fontWeight: 'normal', fontSize: '1rem', textAlign: 'center' }}>
                          {type.DATA_VALUE_ONE === 'total_new_delivered' || type.DATA_VALUE_ONE === 'total_used_delivered' || type.DATA_VALUE_ONE === 'total_trailers_delivered' || type.DATA_VALUE_ONE === 'total_boats_delivered' ? dailyDataForCurrentStore[`${type.DATA_VALUE_ONE}`]?.toLocaleString() : type.DATA_VALUE_ONE === 'total_service_GP' ? "$" + dailyDataForCurrentStore[`${type.DATA_VALUE_ONE}`]?.toLocaleString() : '0'}
                        </td>
                      </tr>
                    </table>
                  </>
                }
              </td>
            </>
          } else {
            return <><td style={{ textAlign: 'center' }}>0</td></>
          }
        })}
      </tr>
    </>
  } else {
    return (
      <>
        <tr>
        </tr>
      </>
    )
  }
};

const renderUsedVehiclesRow = (dailyDataThisMonth, stores, type) => {
  if (dailyDataThisMonth && stores) {
    return <>
      <tr>
        <td style={{ paddingTop: '10px', textAlign: 'center', fontSize: '1rem', fontWeight: 'bolder', color: '', whiteSpace: 'nowrap' }}>
          {type.LABEL}
        </td>
        <td>
          <table style={{ width: '100%' }}>
            <tr>
              <td style={{ fontWeight: 'normal', fontSize: '1rem', whiteSpace: 'nowrap' }}>
                {type.TITLE_TWO}
              </td>
            </tr>
            <tr>
              <td style={{ fontWeight: 'normal', fontSize: '1rem', whiteSpace: 'nowrap' }}>
                {type.TITLE_ONE}
              </td>
            </tr>

          </table>
        </td>
        {stores && stores.map((store) => {
          const dailyDataForCurrentStore = dailyDataThisMonth.find(key => store.id === key.store_id)

          if (dailyDataForCurrentStore) {
            return <>
              <td>
                {type.LABEL === 'Total' ?
                  <>
                    <table style={{ width: '100%' }}><tr style={{ textAlign: 'center' }}>${calculateTotalGP(dailyDataForCurrentStore)}</tr></table>
                  </> :
                  <>
                    <table style={{ width: '100%' }}>
                      {type.LABEL !== 'Services' && <>
                        <tr>
                          <td style={{ fontWeight: 'normal', fontSize: '1rem', textAlign: 'center' }}>
                            ${isValueArray(dailyDataForCurrentStore, type)}
                          </td>
                        </tr>
                      </>
                      }
                      <tr>
                        <td style={{ fontWeight: 'normal', fontSize: '1rem', textAlign: 'center' }}>
                          {type.DATA_VALUE_ONE === 'total_new_delivered' || type.DATA_VALUE_ONE === 'total_used_delivered' ? dailyDataForCurrentStore[`${type.DATA_VALUE_ONE}`].toLocaleString() :  '$0'}
                        </td>
                      </tr>
                    </table>
                  </>
                }
              </td>
            </>
          } else {
            return <><td style={{ textAlign: 'center' }}>0</td></>
          }
        })}
      </tr>
    </>
  } else {
    return (
      <>
        <tr>
        </tr>
      </>
    )
  }
};

const MTDDealerComparisonTable = (props) => {
  const { stores, dailyDataThisMonth, usedStores, division } = props;
  return (
    <table className="table table-bordered border-black table-fit" style={{ marginTop: 20 }}>
      <thead>
        <tr className="text-center">
          <th colSpan="1" className="dn-header-top" style={{ "backgroundColor": "orange" }} >Category</th>
          <th colSpan="1" className="dn-header-top" style={{ "backgroundColor": "orange" }}>Gross</th>
          {division !== 'Used' && stores && stores.map((store) => {
            return <>
              <th colSpan="1" className="dn-header-top" style={{ "backgroundColor": "orange", whiteSpace: 'nowrap' }}>{store}</th>
            </>
          })}
          {division === 'Used' && usedStores && usedStores.map((store) => {
            return <>
              <th colSpan="1" className="dn-header-top" style={{ "backgroundColor": "orange", whiteSpace: 'nowrap' }}>{store.division} {store.name}</th>
            </>
          })}
        </tr>
      </thead>
      <tbody>
        {division !== 'Used' && renderVehiclesRow(dailyDataThisMonth, stores, DEFAULT_TABLE_TITLES.NEW_VEHICLES)}
        {division !== 'Used' && renderVehiclesRow(dailyDataThisMonth, stores, DEFAULT_TABLE_TITLES.USED_VEHICLES)}
        {division !== 'Used' && renderVehiclesRow(dailyDataThisMonth, stores, DEFAULT_TABLE_TITLES.SERVICES)}
        {division !== 'Used' && renderVehiclesRow(dailyDataThisMonth, stores, DEFAULT_TABLE_TITLES.PARTS)}
        {division === 'Rec' && renderVehiclesRow(dailyDataThisMonth, stores, DEFAULT_TABLE_TITLES.BOATS)}
        {division === 'Rec' && renderVehiclesRow(dailyDataThisMonth, stores, DEFAULT_TABLE_TITLES.TRAILERS)}
        {division === 'Rec' && renderVehiclesRow(dailyDataThisMonth, stores, DEFAULT_TABLE_TITLES.APPAREL)}
        {division !== 'Used' && renderVehiclesRow(dailyDataThisMonth, stores, DEFAULT_TABLE_TITLES.TOTAL)}
        {division === 'Used' && renderUsedVehiclesRow(dailyDataThisMonth, usedStores, DEFAULT_TABLE_TITLES.USED_VEHICLES)}
        {division === 'Used' && renderUsedVehiclesRow(dailyDataThisMonth, usedStores, DEFAULT_TABLE_TITLES.SERVICES)}
        {division === 'Used' && renderUsedVehiclesRow(dailyDataThisMonth, usedStores, DEFAULT_TABLE_TITLES.TOTAL)}
      </tbody>
    </table>
  )
};

export default MTDDealerComparisonTable