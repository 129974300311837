import React, { useEffect, useState } from "react";
import axios from "axios";
import Links from "../../../src/Data/Links.js";

const DashboardAccounting = (props) => {
  const { division, Modalmonth, Modalyear, week, columnName } = props;

  const [autoStores, setAutoStores] = useState([]);
  const [allDivisionsData, setAllDivisionData] = useState({});
  const usedDealerships = ['DriveNation', 'FFUN Cars'];

  const styles = {
    inputField: {
      width: 120,
    },
  };

  useEffect(() => {
    getAutoStores();
    getAccountingData();
  }, []);

  const getAutoStores = () => {
    const autoStores = [];
    axios
      .get(Links.getAllStores)
      .then((response) => {
        response.data.stores.forEach((store) => {
          if (division === "Used") {
            if (usedDealerships.includes(store.division.trim())) {
              autoStores.push(store);
            }
          } else {
            if (store.division.trim().toLowerCase() === division.toLowerCase()) {
              autoStores.push(store);
            }
          }
        });
      })
      .then(() => {
        setAutoStores(autoStores);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getAccountingData = () => {
    axios
      .post(Links.ceoAccountingGetData, { week, month: Modalmonth, year: Modalyear, division })
      .then((response) => {
        const theData = response.data.data;
        if (Array.isArray(theData) && theData.length > 0) {
          const allDivisionData = theData.reduce((acc, item) => {
            acc[item.store] = item;
            return acc;
          }, {});
          setAllDivisionData(allDivisionData);
        } else {
          setAllDivisionData({});
          console.log("No data available");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const isValidColumn = (name) => {
    return name === columnName || name === `${columnName}_target`;
  };

  const formatColumnName = (name) => {
    return name
      .replace(/_/g, ' ') // Replace underscores with spaces
      .split(' ') // Split into words
      .map(word => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize the first letter of each word
      .join(' '); // Join back into a single string
  };

  const formatValue = (value, name) => {
    if (value === undefined || value === '-') return 0;

    switch (name) {
      case 'cash_balance':
      case 'used_inventory_120':
      case 'cash_inventory_45':
      case 'cash_inventory_all':
      case 'part_inventory':
      case 'cit_10':
      case 'cash_balance_target':
      case 'used_inventory_120_target':
      case 'cash_inventory_45_target':
      case 'cash_inventory_all_target':
      case 'part_inventory_target':
      case 'cit_10_target':
        return `$${Number(value).toLocaleString()}`;
      case 'used_inventory_120_units':
      case 'used_inventory_120_units_target':
        return Number(value) || 0; // Just numbers
      default:
        return value;
    }
  };

  // Sort autoStores based on the specified column name in descending order
  const sortedStores = [...autoStores].sort((a, b) => {
    const aValue = allDivisionsData[a.id]?.[columnName] || 0;
    const bValue = allDivisionsData[b.id]?.[columnName] || 0;

    return Number(bValue) - Number(aValue); // Sort in descending order
  });

  return (
    <>
      <h4>{`${division} Dealerships (Week-${week}, ${Modalmonth}, ${Modalyear}):`}</h4>
      <table className="mb-5 mt-3 table table-bordered table-fit table-striped">
        <thead>
          <tr className="text-center">
            <th className="dn-header-top" style={{ backgroundColor: "orange" }}>Store name</th>
            {isValidColumn(columnName) && (
              <>
                <th className="dn-header-top" style={{ backgroundColor: "#b1d5dd" }}>{formatColumnName(columnName)}</th>
                <th className="dn-header-top" style={{ backgroundColor: "#b1d5dd" }}>{formatColumnName(`${columnName}_target`)}</th>
              </>
            )}
          </tr>
        </thead>
        <tbody>
          {sortedStores.map((store) => {
            const storeData = allDivisionsData[store.id] || {}; // Get the data for the current store
            return (
              <tr key={store.id}>
                <td style={{ textAlign: "center" }}>
                  {division === "Used" && store.division + " "} {store.name}
                </td>
                {isValidColumn(columnName) && (
                  <>
                    <td style={{ textAlign: "center" }}>
                      {formatValue(storeData[columnName], columnName)} {/* Display formatted data */}
                    </td>
                    <td style={{ textAlign: "center" }}>
                      {formatValue(storeData[`${columnName}_target`], `${columnName}_target`)} {/* Display formatted data */}
                    </td>
                  </>
                )}
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
};

export default DashboardAccounting;
