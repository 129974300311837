import React, { useEffect, useState } from "react";
import axios from "axios";
import Links from "../../../src/Data/Links.js";

const FfunOneDashboardAccounting = (props) => {
  const { division,Modalmonth,Modalyear,week,handleSaveAccounting} = props;
const [currentData, setCurrentData]=useState([]);
  const [autoStores, setAutoStores] = useState([]);
  const [divisions, setDivisions] = useState([]);
  const [allDivisionsData,setAllDivisionData]=useState({});
  const usedDealerships = ['DriveNation', 'FFUN Cars'];
  const styles = {
    inputField: {
        width: 120
    },
    tabForm: {
        zIndex: 99,
        maxHeight: "calc(100% - 40px)",
        maxWidth: 600,
    },
}
  useEffect(() => {
    getAutoStores();
    getAccountingData();
  }, []);


  const accountingData={
    id:"",
    store:"",
    week:"",
    month:"",
    year:"",
    cash_balance:"",
    used_inventory_120_units:"",
    used_inventory_120:"",
    cash_inventory_45:"",
    cash_inventory_all:"",
    part_inventory:"",
    cit_10:"",
    cash_balance_target:"",
    used_inventory_120_units_target:"",
    used_inventory_120_target:"",
    cash_inventory_45_target:"",
    cash_inventory_all_target:"",
    part_inventory_target:"",
    cit_10_target:""
  }

  const getAutoStores = () => {
    const divisions = [];
    const autoStores = [];
    axios
      .get(Links.getAllStores)
      .then((response) => {
        response.data.stores.forEach((store) => {
          if (division === "Used") {
            if (usedDealerships.includes(store.division.trim())) {

              autoStores.push(store)
              divisions.push(store.division);
            }
          } else {
            if (store.division.trim().toLowerCase() === division.toLowerCase()) {

              divisions.push(store.division);
              autoStores.push(store)
            }
          }
        });
      })
      .then(() => {
        setAutoStores(autoStores);
        setDivisions(divisions);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getAccountingData = () => {

    axios
      .post(Links.ceoAccountingGetData,{week,month:Modalmonth,year:Modalyear,division})
      .then((response) => {
    
     const theData=response.data.data;
      // Check if response.data is not empty
      if (Array.isArray(theData) && theData.length > 0) {
       
        // Transform the array into an object with IDs as keys
        const allDivisionData = theData.reduce((acc, item) => {
          acc[item.store] = item; // Use item.id as the key and the whole item as the value
          return acc;
        }, {});

        setAllDivisionData(allDivisionData); // Set the transformed data
      } else {
        // Handle the case when response.data is empty
        setAllDivisionData({}); // Or set to an empty object/array as needed
        console.log("No data available");
      }

      })

      .catch((error) => {
        console.log(error);
      });
  };


  const OnchangeData = (event, name, division,storeid) => {
    setAllDivisionData(prevData => ({
      ...prevData,
      [storeid]: {
        ...prevData[storeid],
        [name]: event.target.value  // Set to initial value if empty
      }
    }));
  };

  const SaveData = (day) => {
    handleSaveAccounting();
  axios.post(Links.ceoAccountingData,{allDivisionsData,week,Modalmonth,Modalyear,division} )
        .then(res => {
   
        }).catch(err => {
            console.log(err);
        });
};


  const divisionTitle = (division) => {
    let title;
    switch (division) {
      case "Auto":
        title = "Automotive";
        break;
      // case "Drive Nation":
      //   title = division;
      //   break;
      case "Rec":
        title = "Recreational";
        break;
      case "Used":
        title = "Used";
        break;
      case "FFUN Financial":
        title = "FFUN Financial";
        break;
      default:
        title = "Automotive";
    }
    return title + " Dealerships";
  };




  return (
    <>
      <h4>{divisionTitle(division) + " (Week-"+week+", "+Modalmonth+", "+Modalyear+")"}:</h4>
      <table className="mb-5 mt-3 table table-bordered table-fit table-striped">
        <thead>
          <tr className="text-center">
          
            <th
              colSpan="1"
              className="dn-header-top"
              style={{ whiteSpace: "nowrap", backgroundColor: "orange" }}
            >
              Store name
            </th>
            <th
              colSpan="1"
              className="dn-header-top"
              style={{
                whiteSpace: "nowrap",
                backgroundColor: "#b1d5dd",
              }}
            >
              Cash Balance
            </th>
            <th
              colSpan="1"
              className="dn-header-top"
              style={{
                whiteSpace: "nowrap",
                backgroundColor: "#b1d5dd",
              }}
            >
              Cash Balance Target
            </th>
            <th
              colSpan="1"
              className="dn-header-top"
              style={{ whiteSpace: "nowrap", backgroundColor: "#b1d5dd" }}
            >
           Used Inventory 120+ Days - Units
            </th>
            <th
              colSpan="1"
              className="dn-header-top"
              style={{ whiteSpace: "nowrap", backgroundColor: "#b1d5dd" }}
            >
           Used Inventory 120+ Days - Units Target
            </th>
            <th
              colSpan="1"
              className="dn-header-top"
              style={{ whiteSpace: "nowrap", backgroundColor: "#b1d5dd" }}
            >
             Used Inventory 120+ Days
            </th>
            <th
              colSpan="1"
              className="dn-header-top"
              style={{ whiteSpace: "nowrap", backgroundColor: "#b1d5dd" }}
            >
             Used Inventory 120+ Days Target
            </th>
            <th
              colSpan="1"
              className="dn-header-top"
              style={{ whiteSpace: "nowrap", backgroundColor: "#b1d5dd" }}
            >
             Cash Inventory 45+ Days
            </th>
            <th
              colSpan="1"
              className="dn-header-top"
              style={{ whiteSpace: "nowrap", backgroundColor: "#b1d5dd" }}
            >
             Cash Inventory 45+ Days Target
            </th>
            <th
              colSpan="1"
              className="dn-header-top"
              style={{
                whiteSpace: "nowrap",
                backgroundColor: "#b1d5dd",
              }}
            >
             Cash Inventory - All
            </th>
            <th
              colSpan="1"
              className="dn-header-top"
              style={{
                whiteSpace: "nowrap",
                backgroundColor: "#b1d5dd",
              }}
            >
             Cash Inventory - All Target
            </th>
            {division!=="Used" && 
            <>
            <th
              colSpan="1"
              className="dn-header-top"
              style={{ whiteSpace: "nowrap", backgroundColor: "#b1d5dd" }}
            >
         Part Inventory (1 yr)
            </th>
            <th
              colSpan="1"
              className="dn-header-top"
              style={{ whiteSpace: "nowrap", backgroundColor: "#b1d5dd" }}
            >
         Part Inventory (1 yr) Target
            </th>
            </>
            }
            <th
              colSpan="1"
              className="dn-header-top"
              style={{ whiteSpace: "nowrap", backgroundColor: "#b1d5dd" }}
            >
             CIT's 10+ Days
            </th>
            <th
              colSpan="1"
              className="dn-header-top"
              style={{ whiteSpace: "nowrap", backgroundColor: "#b1d5dd" }}
            >
             CIT's 10+ Days Target
            </th>
          </tr>
        </thead>
        <tbody>
          {autoStores &&
            autoStores.map((autoStore, index) => {
              const theId=(autoStore.id).toString();
         
                return (
                  <>
                   <tr>
  <td style={{ textAlign: "center" }}>
    {division === "Used" && autoStore.division + " "} {autoStore.name}
  </td>
  <td style={{ textAlign: "center" }}>
    <input 
      onBlur={(e) => { OnchangeData(e, "cash_balance", division, autoStore.id); }} 
      style={styles.inputField} 
      defaultValue={allDivisionsData[theId]?.cash_balance} 
    />
  </td>
  <td style={{ textAlign: "center" }}>
    <input 
      onBlur={(e) => { OnchangeData(e, "cash_balance_target", division, autoStore.id); }} 
      style={styles.inputField} 
      defaultValue={allDivisionsData[theId]?.cash_balance_target } 
    />
  </td>
  <td style={{ textAlign: "center" }}>
    <input 
      onBlur={(e) => { OnchangeData(e, "used_inventory_120_units", division, autoStore.id); }} 
      style={styles.inputField} 
      defaultValue={allDivisionsData[theId]?.used_inventory_120_units } 
    />
  </td>
  <td style={{ textAlign: "center" }}>
    <input 
      onBlur={(e) => { OnchangeData(e, "used_inventory_120_units_target", division, autoStore.id); }} 
      style={styles.inputField} 
      defaultValue={allDivisionsData[theId]?.used_inventory_120_units_target } 
    />
  </td>
  <td style={{ textAlign: "center" }}>
    <input 
      onBlur={(e) => { OnchangeData(e, "used_inventory_120", division, autoStore.id); }} 
      style={styles.inputField} 
      defaultValue={allDivisionsData[theId]?.used_inventory_120 } 
    />
  </td>
  <td style={{ textAlign: "center" }}>
    <input 
      onBlur={(e) => { OnchangeData(e, "used_inventory_120_target", division, autoStore.id); }} 
      style={styles.inputField} 
      defaultValue={allDivisionsData[theId]?.used_inventory_120_target } 
    />
  </td>
  <td style={{ textAlign: "center" }}>
    <input 
      onBlur={(e) => { OnchangeData(e, "cash_inventory_45", division, autoStore.id); }} 
      style={styles.inputField} 
      defaultValue={allDivisionsData[theId]?.cash_inventory_45 } 
    />
  </td>
  <td style={{ textAlign: "center" }}>
    <input 
      onBlur={(e) => { OnchangeData(e, "cash_inventory_45_target", division, autoStore.id); }} 
      style={styles.inputField} 
      defaultValue={allDivisionsData[theId]?.cash_inventory_45_target } 
    />
  </td>
  <td style={{ textAlign: "center" }}>
    <input 
      onBlur={(e) => { OnchangeData(e, "cash_inventory_all", division, autoStore.id); }} 
      style={styles.inputField} 
      defaultValue={allDivisionsData[theId]?.cash_inventory_all } 
    />
  </td>
  <td style={{ textAlign: "center" }}>
    <input 
      onBlur={(e) => { OnchangeData(e, "cash_inventory_all_target", division, autoStore.id); }} 
      style={styles.inputField} 
      defaultValue={allDivisionsData[theId]?.cash_inventory_all_target } 
    />
  </td>
  {division !== "Used" &&  
    <>
      <td style={{ textAlign: "center" }}>
        <input 
          onBlur={(e) => { OnchangeData(e, "part_inventory", division, autoStore.id); }} 
          style={styles.inputField} 
          defaultValue={allDivisionsData[theId]?.part_inventory } 
        />
      </td>
      <td style={{ textAlign: "center" }}>
        <input 
          onBlur={(e) => { OnchangeData(e, "part_inventory_target", division, autoStore.id); }} 
          style={styles.inputField} 
          defaultValue={allDivisionsData[theId]?.part_inventory_target } 
        />
      </td>
    </>
  }
  <td style={{ textAlign: "center" }}>
    <input 
      onBlur={(e) => { OnchangeData(e, "cit_10", division, autoStore.id); }} 
      style={styles.inputField} 
      defaultValue={allDivisionsData[theId]?.cit_10 } 
    />
  </td>
  <td style={{ textAlign: "center" }}>
    <input 
      onBlur={(e) => { OnchangeData(e, "cit_10_target", division, autoStore.id); }} 
      style={styles.inputField} 
      defaultValue={allDivisionsData[theId]?.cit_10_target } 
    />
  </td>
</tr>

                  </>
                );
            })}
          {/* <tr>
            <td></td>
            <td style={{ textAlign: "center" }}>Total</td>
            <td style={{ textAlign: "center" }}>
              {type === "gross"
                ? "$" + totalDailyData.toLocaleString()
                : totalDailyData.toLocaleString()}
            </td>
            <td style={{ textAlign: "center" }}>
              {type === "gross"
                ? "$" + totalBudget.toLocaleString()
                : totalBudget.toLocaleString()}
            </td>
            <td style={{ textAlign: "center" }}>
              {calculatePercentageOfBudget(totalDailyData, totalBudget)}
            </td>
          </tr> */}
        </tbody>
      </table>
      <button onClick={(e) => { SaveData() }}  className="button m-2" style={{float:"left"}} >Save</button>
    </>
  );
};

export default FfunOneDashboardAccounting;
