let apiEndpoint;

if (process.env.NODE_ENV === "development") {
  apiEndpoint = "http://localhost:5000/api";
}

else {
  apiEndpoint = process.env.REACT_APP_API_ENDPOINT;
}

export default {
  dashboard: "/",
  getAutoData: apiEndpoint + "/dailydata",
  getUsedDailydata: apiEndpoint + "/dailydata/get-used-data",
  updateDailyDataById: apiEndpoint + "/dailydata",
  addDailyData: apiEndpoint + "/dailydata",
  addDailyDataForUsed: apiEndpoint + "/dailydata/add-data-for-used",
  deleteDailyData: apiEndpoint + "/dailydata/delete-by-id",
  getAllAutoDailyDataByDate: apiEndpoint + "/dailydata/get-all-by-date",
  getAllUsedDailyDataByDate: apiEndpoint + "/dailydata/get-all-by-date-for-used",

  getRecData: apiEndpoint + "/recdailydata",
  addRecDailyData: apiEndpoint + "/recdailydata",
  updateRecDailyDataById: apiEndpoint + "/recdailydata",
  deleteRecDailyData: apiEndpoint + "/recdailydata/delete-by-id",
  getFilteredRecData: apiEndpoint + "/recdailydata/getFilteredDailyData",
  getAllRecDailyDataByData: apiEndpoint + "/recdailydata/get-all-by-date",

  deleteDailyDataForUsed: apiEndpoint + "/dailydata/delete-data-for-used",
  updateDailyDataForUsed: apiEndpoint + "/dailydata/update-data-for-used",
  isRecordBeingEditedForUsed: apiEndpoint + "/dailydata/check-for-existing-record-for-used",
  getFilteredAutoData: apiEndpoint + "/dailydata/getFilteredDailyData",
  getFilteredUsedData: apiEndpoint + "/dailydata/get-filtered-dailydata-for-used",

  // for ffun financial data entry page
  addFfunFinancialDailyData: apiEndpoint + "/ffdailydata",
  updateFfunFinanciaDailyDataById: apiEndpoint + "/ffdailydata",
  deleteFfunFinancialDailyData: apiEndpoint + "/ffdailydata/delete-by-id",
  getFilteredFfunFinancialData: apiEndpoint + "/ffdailydata/getFilteredDailyData",

  getStoreByDivision: apiEndpoint + "/stores/division",
  getStoreByMultipleId: apiEndpoint + "/stores/multiple-id",

  getFilteredBudget: apiEndpoint + "/budget/dailydata",
  getBudgetForDailydata: apiEndpoint + "/budget/for-dailydata",
  getBudgetForUsedDailydata: apiEndpoint + "/budget/for-used-dailydata",
  deleteBudget: apiEndpoint + "/budget/delete-by-id",

  getFilteredBudgetRec: apiEndpoint + "/recbudget/dailydata",
  getRecBudgetForDailydata: apiEndpoint + "/recbudget/for-dailydata",
  deleteRecBudget: apiEndpoint + "/recbudget/delete-by-id",
  updateRecBudgetById: apiEndpoint + "/recbudget",
  addRecBudgetData: apiEndpoint + "/recbudget",
  getYearlyFilteredBudgetRec: apiEndpoint + "/recbudget/getYearlyFilteredBudget",
  getYearlyFilteredBudgetForUsedRec: apiEndpoint + "/recbudget/getYearlyFilteredBudgetForUsed",

  // for ffun financial budget page
  updateFfunFinancialBudgetById: apiEndpoint + "/ffbudget",
  addFfunFinancialBudgetData: apiEndpoint + "/ffbudget",
  deleteFfunFinancialBudget: apiEndpoint + "/ffbudget/delete-by-id",
  getFfunFinancialBudgetForDailydata: apiEndpoint + '/ffbudget/for-dailydata',
  getYearlyFilteredBudgetForFfunFinancial: apiEndpoint + '/ffbudget/getYearlyFilteredBudget',

  getAllStores: apiEndpoint + "/stores",
  getAllDivisions: apiEndpoint + "/dailydata/divisions",
  getDealerReportAuto: apiEndpoint + "/dailydata/dealer-report-auto",
  getDealerReportUsed: apiEndpoint + "/dailydata/dealer-report-used",
  getDealerReportRec: apiEndpoint + "/recdailydata/dealer-report-rec",

  getDivisionalReport: apiEndpoint + "/dailydata/divisional-report",
  getDivisionalReportForRec: apiEndpoint + "/recdailydata/divisional-report",
  getDivisionalReportForUsed: apiEndpoint + "/dailydata/divisional-report-for-used",
  getDivisionalReportForFF: apiEndpoint + "/ffdailydata/divisional-report",
  getDivisionalReportDailyComparison: apiEndpoint + "/dailydata/divisional-report-daily-comparison",
  getDivisionalReportDailyComparisonForRec: apiEndpoint + "/recdailydata/divisional-report-daily-comparison",
  getDivisionalReportDailyComparisonForUsed: apiEndpoint + "/dailydata/divisional-report-daily-comparison-for-used",

  getExecutiveReport: apiEndpoint + "/dailydata/executive-report",
  getExecutiveReportForRec: apiEndpoint + "/recdailydata/executive-report",
  getExecutiveReportForUsed: apiEndpoint + "/dailydata/executive-report-for-used",
  getExecutiveReportForFF: apiEndpoint + "/ffdailydata/executive-report",

  getFilteredBudgetForDealerReport: apiEndpoint + "/budget/dealer-report",
  getFilteredBudgetForDealerReportRec: apiEndpoint + "/budget/dealer-report-rec",
  getFilteredBudgetForDealerReportUsed: apiEndpoint + "/budget/dealer-report-used",

  getFilteredBudgetForDivisionalReport: apiEndpoint + "/budget/divisional-report",
  getFilteredBudgetForDivisionalReportForRec: apiEndpoint + "/recbudget/divisional-report",
  getFilteredBudgetForDivisionalReportForUsed: apiEndpoint + "/budget/divisional-report-for-used",
  getFilteredBudgetForDivisionalReportForFF: apiEndpoint + "/FFbudget/divisional-report",

  getFilteredBudgetForExecutiveReport: apiEndpoint + "/budget/executive-report",
  getFilteredBudgetForExecutiveReportForRec: apiEndpoint + "/recbudget/executive-report",
  getFilteredBudgetForExecutiveReportForUsed: apiEndpoint + "/budget/executive-report-for-used",
  getFilteredBudgetForExecutiveReportForFF: apiEndpoint + "/ffbudget/executive-report",
  uploadBudgetFile: apiEndpoint + "/budget/upload",
  getYearlyFilteredBudget: apiEndpoint + "/budget/getYearlyFilteredBudget",
  getYearlyFilteredBudgetForUsed: apiEndpoint + "/budget/getYearlyFilteredBudgetForUsed",
  updateBudgetById: apiEndpoint + "/budget",
  updateBudgetByIdForUsed: apiEndpoint + "/budget/update-budget-for-used",
  deleteBudgetForUsed: apiEndpoint + "/budget/delete-by-id-for-used",
  addBudgetData: apiEndpoint + "/budget",
  addBudgetDataForUsed: apiEndpoint + "/budget/create-budget-for-used",
  userLogin: apiEndpoint + "/users",
  userMicrosoftLogin: apiEndpoint + "/users/mc-login",
  saveUser: apiEndpoint + "/users/save",
  getAllUsers: apiEndpoint + "/users/all",
  deleteUser: apiEndpoint + "/users/delete",
  resetUserPassword: apiEndpoint + "/users/reset-password",
  ceoDashboardOprationsDailyDataAuto: apiEndpoint + "/dailydata/ceo-dashboard-operations-report",
  ceoDashboardOprationsDailyDataRec: apiEndpoint + "/recdailydata/ceo-dashboard-operations-report",
  ceoDashboardOprationsDailyDataUsed: apiEndpoint + "/dailydata/ceo-dashboard-operations-report-used",
  ceoDashboardOprationsBudgetDataAuto: apiEndpoint + "/budget/ceo-dashboard-operations-report",
  ceoDashboardOprationsBudgetDataRec: apiEndpoint + "/recbudget/ceo-dashboard-operations-report",
  ceoDashboardOprationsBudgetDataUsed: apiEndpoint + "/budget/ceo-dashboard-operations-report-used",
  addCeoDashboardData: apiEndpoint + "/ceoDashboard/add-data",
  getCeoDashboardData: apiEndpoint + "/ceoDashboard/get-data",
  updateCeoDashboardData: apiEndpoint + "/ceoDashboard/update-data",
  ceoCheckIfLocked: apiEndpoint + "/ceoDashboard/check-if-locked",
  ceoGraphData: apiEndpoint + "/ceoDashboard/get-data-for-graph",
  ceoGraphFfunAvantageData:apiEndpoint+"/ceoDashboard/get-data-for-graph-ffun-advantage",
  ceoGraphOperationsData:apiEndpoint+"/ceoDashboard/get-data-for-graph-operations",
  ceoAccountingData:apiEndpoint+"/ffunOneDashboardAccounting/add-data",
  ceoAccountingGetData:apiEndpoint+"/ffunOneDashboardAccounting/get-data",
  ceoAccountingGetWeeklyData:apiEndpoint+"/ffunOneDashboardAccounting/get-weekly-data",
  getStaffData:apiEndpoint+"/staff/get-staff-data",
  addStaffData:apiEndpoint+"/staff/add-staff-data",
  updateStaffData:apiEndpoint+"/staff/update-staff-data",
  deleteStaff: apiEndpoint + "/staff/delete-by-id",
  getStaffSummary:apiEndpoint+"/staff/get-summary-report",
  getVehicleDataforStaff:apiEndpoint+"/staff/get-vehicle-totals-for-staff-kpi"
};
